




















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import ProductForm from '@/components/common/ProductForm.vue'
import DatePicker from 'vue2-datepicker'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

@Component({
  components: {
    ProductForm,
    DatePicker,
    VuePerfectScrollbar,
    Multiselect
  }
})
export default class CreatePicklPopup extends Vue {
@Prop() popupId!: string
@Prop() brandId!: number
@Prop() OnBoarded!: number
@Prop() LoadingBrand!: boolean
@Prop() surveyFeatureAvailable!: boolean
@Prop() localPicklFeature!: boolean
@Prop() demographicsFilterFeature!: boolean

@Prop() selfieFeatureAvailable!: boolean
@Prop() multiTaskFeature!: boolean
@Prop() reimbursementFeature!: boolean
@Prop() couponFeature!: boolean

public : string = ''
public products: any = []
public productLimitPerPage: number = 100
public productOffset: number = 0
public stores: any = []
public storeLimitPerPage: number = 1500
public storeOffset: number = 0
public step: number = 1
public showProductForm: boolean = false
public categories = []
public tasks: any = []
public selectedRetails: any = []
public productImages:any = []
public selectedStates: any = []
public allRetailers: any[] =[]
public filters: any[] = [
  // {
  //   key: 'gender', label: 'Gender'
  // },
  // {
  //   key: 'age_group', label: 'Age Group'
  // },
  {
    key: 'ethnic_background', label: 'Ethnic Background'
  }
  // {
  //   key: 'pets', label: 'Pets'
  // },
  // {
  //   key: 'children', label: 'Children'
  // },
  // {
  //   key: 'language', label: 'Language'
  // }
]
public filterOptions: any =
  {
    'gender': ['Male', 'Female', 'Non-Binary'],

    'age_group': ['18-24', '25-34', '35-44', '45-54', '55-64', '65-80'],

    'ethnic_background': ['Multiracial/Mixed', 'Hispanic/LATAM', 'African American/Black',
      'White', 'Asian or Pacific Islander', 'Middle Eastern', 'Native American/Other'],

    'pets': ['Dog', 'Cat', 'Bird/Small Animal', 'Reptile/Other', 'No Pets'],

    'children': ['1 child', '2 children', '3 or more children', 'No children'],

    'language': ['English', 'Spanish', 'German', 'French', 'Hebrew'],

    '': []
  }

public pickl: any = {
  launchType: 'retailer-specific-location',
  campaignName: '',
  unlockCode: '',
  pickl_multiple_products: 0,
  coupon_id: '',
  survey_id: '',
  start_date_time: '',
  task_ids: [],
  location_ids: [],
  immediate_start: false,
  description: '',
  autoExtend: false,
  extendCounter: 0,
  launchArea: '',
  extendDays: 0,
  takeSelfie: 0,
  multipleResponses: 0,
  responseCounter: '',
  hasReimbursement: 0,
  reimbursementCredits: 0,
  countryId: 234,
  location: '',
  maxTasksPerUser: '',
  selectedRetailerId: '',
  selectedStateIds: [],
  products: [
    {
      product_id: '',
      description: '',
      task_ids: []
    }
  ],

  filters: [
    {
      type: '',
      values: []
    }
  ]
}
public filter: any = {
  type: '',
  value: ''
}
public selectedStores: any[] = []
public selectAll: boolean = false
public citiesSelectAll: boolean = false
public showLimitError: boolean = false
public storeName: string = ''
public storeCity: string = ''
public storeZipCode: string = ''
public storeState: string = ''
public taskLimitPerPage: number = 100
public taskOffset: number = 0
public submittingForm = false
public val: any = ''
public date: Date = new Date()
public time: Date = new Date()
public settings: any = {}
public loadingCredits: boolean = true
public busy: boolean = false
public lowSrcQuery: string = '?w=300&q=100&fit=crop'
public states: [] = []
public cities: [] = []
public stateId: string = ''
public countryId: number = 234
public retailers: [] = []
public storeId: string = ''
public countries: [] = []
public duplicatePicklErrorMessage: string = ''

public limit = 100
public offset = 0
public actionSurvey: any = null
public surveys: any[] = []
public totalCount = 0
public locationCatalogues:any[] =[]
public maxExtendDays: any[] = []
public maxExtendCounter: any[] = []
public showSurveyField: boolean = false
public surveyUnavailableMessage: string = ''
public limitPerPage:number = 100
public surveysOffset:number = 0
public selectedLocationCatalogue:number = 0
public CatalogueSelectedStoreLocations: any[] = []
public reimbursementCredits:number = 0
public brandCoupons: any = []
public StepTwoErrors: boolean = false
public duplicatePicklLocationIds: any[] = []
public hasRetailerStore : boolean = false
public userRole: String = ''
public selectedCities: any = []
get checkIfStateRetailerSelected () {
  if (this.stateId !== '' && this.storeId !== '') {
    return true
  } else {
    return false
  }
}

get creditsLeft () {
  let credits = 0
  if (this.pickl.multipleResponses === true) {
    credits = this.$store.state.brandCreditsRemaining - this.selectedRetails.length * this.pickl.responseCounter
  } else {
    credits = this.$store.state.brandCreditsRemaining - this.selectedRetails.length
  }

  return credits
}
get counterDropDownList () {
  let totalCreditsleft = this.$store.state.brandCreditsRemaining
  let counterList = [2, 3, 4]
  let counter = 0
  let incrementValue = 5
  while (counter <= totalCreditsleft) {
    if (counter >= 50) {
      incrementValue = 25
    }
    if (counter >= 300) {
      incrementValue = 100
    }
    counter = counter + incrementValue
    if (counter <= totalCreditsleft) {
      counterList.push(counter)
    }
  }
  return counterList
}

get counterReimbursementDropDownList () {
  let totalCreditsleft = this.reimbursementCredits
  let counterList = []
  let counter = 0
  let incrementValue = 1
  while (counter <= totalCreditsleft) {
    if (counter >= 5) {
      incrementValue = 5
    }
    counter = counter + incrementValue
    if (counter <= totalCreditsleft) {
      counterList.push(counter)
    }
    if (counter === 5) {
      break
    }
  }
  return counterList
}
removeFilter (index: number) {
  this.pickl.filters.splice(index, 1)
}
addMoreFilter () {
  this.pickl.filters.push(
    {
      type: '',
      values: []
    }
  )
}
getStateCities () {
  this.$store.dispatch('getStateCities', { stateId: this.stateId }).then((response: any) => {
    this.cities = response.response.body
  })
}

makeSelectedFilterOptionsEmpty (index: number) {
  this.pickl.filters[index].values = []
}

setProductImage (productId:number, index:number) {
  console.log()
  this.products.forEach((product: any) => {
    if (product.id === productId) {
      this.productImages[index] = product.image
    }
  })
}
inSelectedStatesList (store: any) {
  let isObjectPresent = this.selectedStates.find((o:any) => o.id === store.id)
  if (typeof isObjectPresent !== 'undefined') {
    return true
  } else {
    return false
  }
}

inSelectedCitiesList (city: any) {
  let isObjectPresent = this.selectedCities.find((o:any) => o.id === city.id)
  if (typeof isObjectPresent !== 'undefined') {
    return true
  } else {
    return false
  }
}

addProductToPickl () {
  this.pickl.products.push(
    {
      product_id: '',
      description: '',
      task_ids: []
    }
  )
}

step1 () {
  if (!this.pickl.immediate_start) {
    let dateTime = this.date
    dateTime.setHours(this.time.getHours(), 0, 0)
    if (new Date().getTime() > dateTime.getTime()) {
      this.$notify({ text: 'Start Date time should be later than current time', type: 'error' })
      return false
    }
    this.pickl.start_date_time = dateTime.getTime() / 1000
  }
  this.$validator.validateAll('select-product-form').then((result) => {
    if (result) {
      this.step = 2
    }
  })
}
step2 () {
  let hasError = false
  let multipleProductsSelected = false
  if (this.pickl.products.length > 1) {
    multipleProductsSelected = true
  }
  this.pickl.products.forEach((product: any) => {
    if (product.product_id === '') {
      this.$notify({ text: 'Please Select Products', type: 'error' })
      this.StepTwoErrors = true
      hasError = true
      return false
    }
    if (product.task_ids.length === 0) {
      this.$notify({ text: 'Please Select task images for product', type: 'error' })
      hasError = true
      return false
    }
    if (multipleProductsSelected && product.task_ids.length > this.$store.state.settings.max_tasks_per_product) {
      this.$notify({ text: 'You can select max ' + this.$store.state.settings.max_tasks_per_product + ' task images per product ', type: 'error' })
      hasError = true
      return false
    }
    if (!multipleProductsSelected && product.task_ids.length > this.$store.state.settings.max_tasks_per_pickl) {
      this.$notify({ text: 'You can select max ' + this.$store.state.settings.max_tasks_per_pickl + ' task images per product ', type: 'error' })
      hasError = true
      return false
    }
  })
  if (!hasError) {
    this.step = 3
  }
}

getCatalogueStoreLocations () {
  this.$store.dispatch('getCatalogueStoreLocations', { brandId: this.brandId, locationCatalogueId: this.selectedLocationCatalogue }).then((response: any) => {
    this.CatalogueSelectedStoreLocations = response.response.body.locations
    this.stores = []
    console.log(this.CatalogueSelectedStoreLocations)
    this.CatalogueSelectedStoreLocations.forEach((catalogue: any) => {
      this.stores.push(catalogue.store_location)
    })
    this.selectAll = true
    this.onSelectAll(false)
  })
}

get checkGoPicklValidation () {
  if (this.pickl.launchType === 'retailer-specific-location') {
    if (this.selectedRetails.length === 0) {
      return true
    }
    if (this.pickl.multipleResponsegxs === true && this.pickl.responseCounter === '') {
      return true
    }
  }
  if (this.pickl.launchType === 'retailer-any-location') {
    if (this.pickl.launchArea === 'cities') {
      if (this.selectedCities.length === 0 || this.pickl.selectedRetailerId === '' || this.pickl.responseCounter === '') {
        return true
      }
    } else {
      if (this.selectedStates.length === 0 || this.pickl.selectedRetailerId === '' || this.pickl.responseCounter === '') {
        return true
      }
    }
  }
  if (this.pickl.launchType === 'global-any-location') {
    if (this.pickl.location === '') {
      return true
    }
    if (this.selectedStates.length === 0) {
      return true
    }
  }
  return false
}
addProduct () {
  this.busy = true;
  (this.$refs.productForm as ProductForm).addProduct()
}

setSelected (store: any, selected: boolean) {
  // if (this.pickl.multipleResponses === true) {
  //   if (this.selectedRetails.length === 1) {
  //     this.$notify({ text: 'Multi responses task can be launched at one location at a time!', type: 'error' })
  //     return false
  //   }
  // }
  if (this.creditsLeft === 0) {
    this.$notify({ text: 'You\'ve reached your maximum credits', type: 'error' })
    return false
  }
  let isObjectPresent = this.selectedRetails.findIndex((o:any) => o.id === store.id)
  if (isObjectPresent === -1) {
    this.selectedRetails.push({ id: store.id, name: store.store.name, address: store.address, image: store.store.image })
  } else {
    this.selectedRetails.splice(isObjectPresent, 1)
  }
}
setStateSelected (state: any, selected: boolean) {
  let isObjectPresent = this.selectedStates.findIndex((o:any) => o.id === state.id)
  if (isObjectPresent === -1) {
    this.selectedStates.push({ id: state.id, name: state.name })
  } else {
    this.selectedStates.splice(isObjectPresent, 1)
  }
}
setCitySelected (city: any, selected: boolean) {
  let isObjectPresent = this.selectedCities.findIndex((o:any) => o.id === city.id)
  if (isObjectPresent === -1) {
    this.selectedCities.push({ id: city.id, name: city.name })
  } else {
    this.selectedCities.splice(isObjectPresent, 1)
  }
}
getBrandLocationCatalogues () {
  this.$store.dispatch('getLocationCatalogueList', { query: '', limit: this.limit, offset: this.offset, brand_id: this.brandId }).then((response) => {
    if (response.options.limit === this.limit && this.offset === response.options.offset) {
      this.locationCatalogues.push(...response.response.body.locationCatalogues)
    }
  }, (response) => {
    this.busy = false
  })
}

inSelectedStoresList (store: any) {
  let isObjectPresent = this.selectedRetails.find((o:any) => o.id === store.id)
  if (typeof isObjectPresent !== 'undefined') {
    return true
  } else {
    return false
  }
}

removeRetailer (index: number) {
  this.selectedRetails.splice(index, 1)
}
removeCity (index: number) {
  this.selectedCities.splice(index, 1)
}
removeState (index: number) {
  this.selectedStates.splice(index, 1)
}

onSelectAllState (event: any) {
  if (this.selectAll) {
    this.states.forEach((state: any) => {
      let isObjectPresent = this.selectedStates.find((o:any) => o.id === state.id)
      if (typeof isObjectPresent !== 'undefined') {
        return false
      }
      this.selectedStates.push({ id: state.id, name: state.name })
    })
  } else {
    this.states.forEach((state: any) => {
      let selectedStateIndex = this.selectedStates.findIndex((o:any) => o.id === state.id)
      if (selectedStateIndex !== -1) {
        this.removeRetailer(selectedStateIndex)
      }
    })
  }
}
onSelectAllCities (event: any) {
  if (this.citiesSelectAll) {
    this.cities.forEach((city: any) => {
      let isObjectPresent = this.selectedCities.find((o:any) => o.id === city.id)
      if (typeof isObjectPresent !== 'undefined') {
        return false
      }
      this.selectedCities.push({ id: city.id, name: city.name })
    })
  } else {
    this.cities.forEach((city: any) => {
      let selectedCityIndex = this.selectedCities.findIndex((o:any) => o.id === city.id)
      if (selectedCityIndex !== -1) {
        this.removeCity(selectedCityIndex)
      }
    })
  }
}

onSelectAll (event: any) {
  if (this.selectAll) {
    if (this.creditsLeft < this.updatedStores.length) {
      this.$notify({ text: 'You\'ve reached your maximum credits', type: 'error' })
      return false
    }
    this.updatedStores.forEach((store: any) => {
      let isObjectPresent = this.selectedRetails.find((o:any) => o.id === store.id)
      if (typeof isObjectPresent !== 'undefined') {
        return false
      }
      let selectedRetailerIndex = this.selectedRetails.findIndex((o:any) => o.id === store.id)
      if (selectedRetailerIndex === -1) {
        this.selectedRetails.push({ id: store.id, name: store.store.name, address: store.address, image: store.store.image })
      }
    })
  } else {
    this.updatedStores.forEach((store: any) => {
      let selectedRetailerIndex = this.selectedRetails.findIndex((o:any) => o.id === store.id)
      if (selectedRetailerIndex !== -1) {
        this.removeRetailer(selectedRetailerIndex)
      }
    })
  }
}
getTaskList () {
  this.$store.dispatch('getTasks', { limit: this.taskLimitPerPage, offset: this.taskOffset }).then((response: any) => {
    this.tasks.push(...response.response.body.data)
    if (this.tasks.length < response.response.body.count) {
      this.taskOffset += this.taskLimitPerPage
      this.getTaskList()
    }
  })
}
removeProductFromPickl (index: number) {
  let elem : any = ''
  elem = document.getElementById('product-link-0')
  elem.click()
  window.scrollTo(0, 0)

  this.pickl.products.splice(index, 1)
}
ValidateAndCreatPickl () {
  this.pickl.location_ids = []
  this.selectedRetails.forEach((x: any) => {
    this.pickl.location_ids.push(x.id)
  })

  if (this.pickl.launchType === 'retailer-specific-location' && this.pickl.location_ids.length === 0) {
    this.$notify({ text: 'Please select Stores', type: 'error' })
    return false
  }
  if (this.pickl.multipleResponses === true && this.pickl.responseCounter === '') {
    this.$notify({ text: 'Please select response counter', type: 'error' })
    return false
  }
  this.pickl.product_id = this.pickl.products[0].product_id
  this.pickl.selectedStateIds = this.selectedStates.map((state: any) => state.id)
  this.pickl.selectedCityIds = this.selectedCities.map((state: any) => state.id)
  this.createPickl()
}
createPickl () {
  this.submittingForm = true
  this.duplicatePicklErrorMessage = ''
  this.$store.commit('showLoading')
  this.$store.dispatch('addPickl', { brandId: this.brandId, pickl: this.pickl }).then(() => {
    this.$emit('UpdatePicklList')
    // let closeCreatePicklPopupButton: HTMLElement = this.$refs.closeCreatePicklPopupButton as HTMLElement
    // closeCreatePicklPopupButton.click()
    this.$notify({ type: 'success', text: 'PICKL has been created successfully. You want to blast more pickls?' })
    this.submittingForm = false
    this.selectAll = false
    this.storeName = ''
    this.storeCity = ''
    this.storeZipCode = ''
    // this.stores = []
    this.selectedStores = []
    this.selectedRetails = []
    // this.step = 1
    this.onSelectAll(false)
    this.date = new Date()
    this.time = this.date
    this.time.setHours(this.time.getHours() + 1)
    this.$store.commit('showMessage', { message: 'GOOOO PICKL!' })
    this.selectedCities = []
    this.pickl.maxTasksPerUser = ''
    this.pickl.responseCounter = ''
    // this.stateId = ''
    // this.getStoresList()
  }, (response) => {
    for (var key in response.body) {
      if (response.status === 400) {
        // this.$notify({ type: 'error', text: response.body[key][0] })
        this.duplicatePicklErrorMessage = response.body.message
        this.duplicatePicklLocationIds = response.body.locationIds
      } else {
        this.$notify({ type: 'error', text: response.body[key][0] })
      }
      let field = this.$validator.fields.find({ name: key, scope: 'select-product-form' })
      if (field) {
        this.$validator.errors.add({
          id: field.id,
          field: key,
          msg: response.body[key][0],
          scope: 'select-product-form'
        })
      }
    }
    this.submittingForm = false
    this.$store.commit('hideMessage')
  })
}
get updatedStores () {
  let filteredStores = this.stores
  if (this.storeName !== '') {
    filteredStores = filteredStores.filter((m: any) => (m.store.name.toLowerCase().search(this.storeName.trim().toLowerCase()) > -1))
  }
  if (this.storeCity !== '') {
    filteredStores = filteredStores.filter((m: any) => (m.state && m.city.toLowerCase().search(this.storeCity.trim().toLowerCase()) > -1))
  }
  if (this.storeState !== '') {
    filteredStores = filteredStores.filter((m: any) => (m.state && m.state.toLowerCase().search(this.storeState.trim().toLowerCase()) > -1))
  }
  if (this.storeZipCode !== '') {
    filteredStores = filteredStores.filter((m: any) => (m.zip_code && m.zip_code.toLowerCase().search(this.storeZipCode.trim().toLowerCase()) > -1))
  }
  for (var i = 0; i < filteredStores.length; ++i) {
    if (this.selectedStores.indexOf(filteredStores[i]) === -1) {
      this.selectAll = false
      break
    } else if (filteredStores.length - 1 === i) this.selectAll = true
  }
  return filteredStores
}

removeDuplicateLocations () {
  console.log(this.duplicatePicklLocationIds)
  this.duplicatePicklLocationIds.forEach((id:any) => {
    let isObjectPresent = this.selectedRetails.findIndex((o:any) => o.id === id)
    this.selectedRetails.splice(isObjectPresent, 1)
  })
}

refreshProductList () {
  this.busy = false
  this.showProductForm = false
  this.products = []
  this.getProducts()
  this.$emit('refreshProductList')
}

getProducts () {
  this.$store.dispatch('getBrandProducts', { brandId: this.brandId, limit: this.productLimitPerPage, offset: this.productOffset, sort: 'name-ASC' }).then((response: any) => {
    this.products.push(...response.response.body.data)
    if (this.products.length < response.response.body.count) {
      this.productOffset += this.productLimitPerPage
      this.getProducts()
    }
  })
}

getStoreLocations (clear: boolean = true): void {
  this.$store.dispatch('getStoreLocations', { limit: this.storeLimitPerPage, offset: this.storeOffset, sort: 'name-ASC', storeId: this.storeId, stateId: this.stateId, city: this.storeCity, zipCode: this.storeZipCode, showEnabled: 1 }).then((response: any) => {
    this.stores.push(...response.response.body.data)
    if (this.stores.length < response.response.body.count) {
      this.storeOffset += this.storeLimitPerPage
      this.getStoreLocations()
    }
  })
}

getCountries () {
  this.$http.get('countries?service_enabled=true').then((response: any) => {
    this.countries = response.body.data
  })
}

getStates () {
  this.stateId = ''
  this.stores = []
  this.selectedStores = []
  this.retailers = []
  this.states = []
  this.storeZipCode = ''
  this.storeCity = ''
  this.$store.commit('showLoading', 'Loading States')
  this.$http.get('states?country_id=' + this.pickl.countryId).then((response: any) => {
    this.states = response.body.data
    this.$store.commit('hideMessage')
  })
}

getStoresList () {
  this.$store.commit('showLoading', 'Loading Retailers')
  this.storeId = ''
  this.stores = []
  this.selectedStores = []
  this.retailers = []
  this.storeZipCode = ''
  this.storeCity = ''
  if (this.stateId !== '') {
    this.$http.get('stores/get-by-state/' + this.stateId).then((response: any) => {
      this.retailers = response.body.data
      this.$store.commit('hideMessage')
    })
  }
}

getAllRetailers () {
  this.$store.dispatch('getStores', { limit: 1000, offset: 0, sort: 'name-ASC' }).then((response: any) => {
    // console.log(response.response.body.data)
    this.allRetailers.push(...response.response.body.data)
  })
}

updateStoreHandler () {
  this.stores = []
  this.storeOffset = 0
  this.selectedStores = []
  this.storeZipCode = ''
  this.storeCity = ''
  if (this.storeId !== '') {
    this.getStoreLocations()
  }
}

getNonGeoSurveys () {
  this.$store
    .dispatch('getSurveysList', {
      limit: this.limitPerPage,
      offset: this.surveysOffset,
      brand_id: this.brandId,
      type: 'GEO'
    })
    .then(
      response => {
        this.surveys.push(...response.response.body.surveys)
      },
      response => {}
    )
}

getBrandCoupons () {
  this.$store.dispatch('getBrandCoupons', { brandId: this.brandId, limit: 100, offset: 0, type: 'PICKL' }).then(response => {
    this.brandCoupons = response.response.body.coupons
  })
}
getLocationCatalogues () {
  this.$store.dispatch('getBrandLocationCatalogueas', { limit: this.limit, offset: this.offset }).then((response: any) => {
    this.tasks.push(...response.response.body.data)
    if (this.tasks.length < response.response.body.count) {
      this.taskOffset += this.taskLimitPerPage
      this.getTaskList()
    }
  })
}

checkSurveyAvailibility () {
  if (this.$store.state.settings.enable_survey_feature === '1') {
    if (this.surveyFeatureAvailable === true) {
      this.showSurveyField = true
      this.getNonGeoSurveys()
    } else {
      this.surveyUnavailableMessage = 'Upgrade your plan to use this feature'
    }
  } else {
    this.surveyUnavailableMessage = 'Coming soon! Contact PICKL support for more info.'
  }
}

mounted () {
  // console.log(this.localPicklFeature)
}
created () {
  this.loadingCredits = true
  this.time.setHours(this.time.getHours() + 1)
  this.$store.dispatch('getBrandCurrentSubscription', this.brandId).then((response) => {
    this.loadingCredits = false
    this.reimbursementCredits = response.response.body.data.reimbursement_credits
  })
  this.getProducts()
  this.$store.dispatch('getAppSettingsKeyValuePair').then(() => {
    this.pickl.pickl_multiple_products = this.$store.state.settings.pickl_multiple_products
    for (var i = 1; i <= this.$store.state.settings.max_extend_counter; i++) {
      this.maxExtendCounter.push(i)
    }
    this.maxExtendDays = this.$store.state.settings.max_extend_days

    this.checkSurveyAvailibility()
  })
  this.getTaskList()
  this.getCountries()
  this.getStates()
  this.getBrandLocationCatalogues()
  this.getBrandCoupons()
  this.getAllRetailers()
  let userData = this.$store.state.userData
  this.userRole = userData.role
}
@Watch('surveyFeatureAvailable')
onSurveyAvailibilityChange () {
  this.checkSurveyAvailibility()
}
@Watch('pickl.multipleResponses')
onMultipleResponsesChange () {
  this.selectedRetails = []
}
}
